export const environment = {
    production: false,
    isAdmin: false,
    adminUrl:"uat.admin.alda.avnet.com",
    apiURL:"https://uat.api.alda.avnet.com/api/",
    clientId: '9b046d5e-2b71-4d93-919b-f284afa9eae4',
    tenantId: '8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    authority: 'https://login.microsoftonline.com/8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    redirectUri: 'https://uat.admin.alda.avnet.com/login/callback',
    postLogoutRedirectUri: 'https://uat.admin.alda.avnet.com/',
    grapAPI: 'https://graph.microsoft.com/v1.0/me',
    apiDomain: 'https://uat.api.alda.avnet.com/',
    scopes: 'api://a6dcfbf9-e3d6-4e08-8a47-601b212e9a05/Logistic-api-access',
    perPageOptions: [5, 10, 20, 30, 50, 100],
    pageSize: 10,
    reSendOTPTimeInSecond:60,
    adminAccessGroupName:"avt-amer-npr-logistic-deskless-app-admin"
};